var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "anchorList" }, [
    _c("h3", { staticClass: "anchorList__headline" }, [
      _vm._v("\n        " + _vm._s(_vm.headline) + "\n    "),
    ]),
    _vm._v(" "),
    _c(
      "ol",
      { staticClass: "anchorList__list" },
      _vm._l(_vm.anchors, function (anchor) {
        return _c(
          "li",
          { staticClass: "anchorList__item" },
          [
            anchor.url !== null
              ? _c("link-wrap", { attrs: { link: anchor.url } }, [
                  _c("span", { domProps: { innerHTML: _vm._s(anchor.label) } }),
                ])
              : _vm._e(),
          ],
          1
        )
      }),
      0
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }