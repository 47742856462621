

































import {PlainObject} from '@labor-digital/helferlein/lib/Interfaces/PlainObject';
import {ContentElementContext} from '@labor-digital/typo3-vue-framework/lib/Core/Context/ContentElementContext';
import LinkWrap from '../../../Component/Misc/Link/LinkWrap/LinkWrap.vue';

export default {
    name: 'AnchorList',
    components: {LinkWrap},
    props: {
        context: null as ContentElementContext
    },
    computed: {
        headline(): Array<PlainObject>
        {
            return this.context.data.get('headline', '');
        },
        anchors(): Array<PlainObject>
        {
            return this.context.data.get('anchors', []);
        }
    }
};
